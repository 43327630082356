$primary-color: #f44336;

html, body, h1, h2, h3, .mdl-button {
  font-family: "Roboto", "Helvetica", "Arial", "Shin Go Regular", "新ゴ R", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}

small {
  font-size: 12px;
}

.pos-layout {
  position: relative;
  padding-top: 80px;
}

header {
  position: fixed;
  top: 0;

  > h1 {
    float: left;
    line-height: 50px;
    margin: 0 0 0 20px;
  }

  nav {
    float: right;
  }

  nav > ul {
    display: flex;

    > li {

      > a {
        display: block;
        padding: 0 20px;
        line-height: 64px;
        text-align: center;
        text-decoration: none;
        font-size: 14px;
        color: #6b6b6b;

        &:hover {
          background-color: lightgray;
        }
      }
    }
  }
}

main.mdl-layout__content {
  overflow-y: initial;
  overflow-x: initial;
}

footer {
  padding: 4vw;
  background-color: #2b2525;
  font-size: 12px;

  > nav > ul {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    > li + li {
      margin-left: 1em;
    }
  }

  a {
    color: #f7f7f7;
    text-decoration: none;

    &:hover {
      color: lighten($primary-color, 56%);
      text-decoration: underline;
    }
  }

  > small {
    display: block;
    color: #a7a7a7;
    font-size: 12px;
    text-align: center;
  }

  > * + small {
    margin-top: 1em;
  }
}

aside {
  position: absolute;
  z-index: -1;
  top: -90000px;
}

.container {
  width: 100%;
  padding: 4vw;
  box-sizing: border-box;
  background-color: #ffffff;
  font-size: 14px;

  > h2 {
    text-align: center;
    margin-bottom: 30px;
    color: $primary-color;
  }

  & + .container {
    border-top: solid 1px #f6f6f6;
  }

  &.container--centering {
    padding-left: 16vw;
    padding-right: 16vw;
  }
}


.logo {
  color: $primary-color;
  font-weight: normal;
  text-decoration: none;
  font-size: 16px;
}

.swiper-container {
  position: absolute;
  background-color: #121212;
  top: 0;
  left: 0;
  width: 100vw;
  height: 480px;
  z-index: 0;

  .swiper-slide {
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.hero {
  position: relative;
  height: 480px;
  box-sizing: border-box;
  padding: 80px 20px 0;

  > h1, h2 {
    color: #ffffff;
    text-align: center;
    font-weight: normal;
    text-shadow: 0px 0px 1px #c5c5c5;
  }

  > h1 + h2 {
    margin-top: 12px;
  }

  > .hero__footer {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -36px;
      text-align: center;

    > .md-button {
      background-color: #fff;

      &:hover {
        background-color: #fff;
      }

      > i {
        position: absolute;
        bottom: 16px;
        left: 0;
        right: 0;
        margin: auto;
        color: $primary-color;
      }
    }
  }
}

.howtouse {
  > h2 + p {
    padding-top: 1rem;
    text-align: center;
    font-size: 1rem;
  }

  > * + h3 {
    margin-top: 2rem;
    font-size: 1.2rem;
    color: #666;
  }

  > h3 + p {
    margin-bottom: 0;
  }
}

.api-try {
  margin: 0 auto;
  min-height: 140px;
  text-align: center;

  > span {
    color: #FFEBEE;
    font-size: 1.5rem;
    font-weight: normal;
    letter-spacing: 2px;
  }

  > md-input-container {
    position: relative;
    width: 8.5rem;

    > label {
      position: absolute;
      left: -40px;
      bottom: 52px;
      color: rgba(255, 255, 255, 0.7);
    }

    &.md-input-has-value > label {
      color: rgba(255, 255, 255, 0.7);
    }

    > input {
      position: relative;
      top: 8px;
      color: #FFEBEE;
      font-size: 1.5rem;
      letter-spacing: 2px;
      border-bottom-color: rgba(255, 255, 255, 0.7);
    }
  }

  > .api-try__results {
    width: 60vw;
    margin: 0 auto;
    background: rgba(255,255,255, 0.8);
    padding: 8px;
    border: solid 1px #ccc;
    box-sizing: border-box;
    border-radius: 5px;
    white-space: normal;
    text-align: left;
  }
}

.api-publish {
  margin-top: 2rem;
  text-align: center;
}

.features {
  background-color: #f6f6f6;
  padding-top: 6vw;

  > .features-list {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    > li {
      width: 25%;
      background-color: #ffffff;
      padding: 16px;
      box-sizing: border-box;

      > h3 {
        font-size: 1.125rem;
        line-height: 1.5;
        min-height: 3em;
        font-weight: normal;
        text-align: center;
      }

      > p {
        font-size: 0.875rem;

        &.symbol {
          text-align: center;

          > i {
            font-size: 6rem;

            &.color-simple {
              color: #64B5F6; // 300;
            }

            &.color-autoupdate {
              color: #4DD0E1; // 300;
            }

            &.color-office {
              color: #81C784; // 300;
            }

            &.color-cloud {
              color: #DCE775 // 300;
            }
          }
        }
      }
    }

    > li + li {
      margin-left: 1%;
    }
  }

  > h2 + .features-list {
    margin-top: 60px;
  }
}

.form > ul {
  max-width: 40rem;
  margin: 3rem auto 0;

  > li {
    > md-input-container {
      display: block;
    }

    > md-checkbox a {
      position: relative;
      z-index: 2;
    }

    &.form-items__actions {
      margin-top: 2rem;
      text-align: center;
    }
  }
}

.api-demo {
  position: relative;

  > div {
    font-size: 1.4rem;

    > .api-demo__method {
      position: relative;
      top: -2px;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 0.875rem;
      background-color: #388e3c; // 700
      color: #fff;
    }

    > md-input-container {
      width: 108px;
      top: 10px;
      margin-right: 8px;

      > label {
        font-size: 1rem;
      }

      > div[ng-messages] {
        position: absolute;
        display: inline-block;
        width: 320px;
        top: 32px;
        left: 0;
      }
    }
  }


  > div + button {
    position: absolute;
    top: 20px;
    right: 0;
  }

  pre.pretty-json {
    padding: 8px;
    box-sizing: border-box;
    border: solid 1px #ccc;
    border-radius: 5px;
    background-color: #f8f8f8;
  }
}

.prices {
  background-color: #f6f6f6;

  > h2 + .prices-list {
    margin-top: 50px;
  }

  > .prices-list {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;

    > li {
      width: 33%;
      border: solid 1px #cccccc;
      background-color: #ffffff;

      > h3 {
        padding: 15px 10px;
        font-size: 1.125rem;
        color: #757575; // Gray, 600
        text-align: center;
      }

      > div.price {
        padding: 10px;
        background-color: #757575; // Gray, 600
        color: #ffffff;
        font-family: Roboto, "Helvetica Neue", sans-serif;
        height: 150px;
        line-height: 150px;
        text-align: center;
        font-size: 1.5rem;

        > span {
          font-size: 3rem;
        }
      }

      > .prices-list-action {
        padding: 10px 0;
        text-align: center;
      }

      &.prices-list-recommend {
        > h3 {
          color: #1976d2; // Blue, 700
        }

        > div.price {
          background-color: #1976d2; // Blue, 700
        }
      }
    }

    > li + li {
      margin-left: 2%;
    }
  }

  > .prices-list  + p {
    margin-top: 30px;
  }

  .price-details {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;

    > li {
      width: 100%;
      padding: 15px 0;
      text-align: center;
      border-bottom: solid 1px #f6f6f6;
    }
  }
}

.inquiry {
  background-color: #ffffff;

  > .inquiry__is-sending {
    text-align: center;

    > md-progress-circular + p {
      padding-top: 5rem;
      font-size: 0.875rem;
    }
  }
}

.terms {
  > ol {
    list-style-position: inside;
    list-style-type: decimal;

    > li {
      font-size: 1rem;
      padding-bottom: 1rem;

      > p {
        padding: 1rem 1rem 0;
        margin-bottom: 0;
      }

      > ul, > ol, > dl {
        margin-left: 1rem;
        padding: 1rem 1rem 0;

        > li, > dt, > dd {
          font-size: 0.875rem;
        }
      }

      ul, ol {
        margin: 0 1rem;

        > li > ul, > li > ol {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }

      ul {
        list-style-type: katakana;
      }

      ol {
        list-style-position: outside;
        list-style-type: decimal;
      }
    }

    > li + li {
      margin-top: 1rem;
    }
  }

  dl {
    > dt {
      font-weight: normal;
    }

    > dd {
      margin-left: 1rem;
    }

    > dd + dt {
      margin-top: 1rem;
    }
  }

  > ol + dl {
    margin-top: 2rem;

    > dt {
      font-size: 1rem;
    }

    > dt + dd {
      padding-top: 1rem;
    }
  }
}

.business-deal {
  > h2 + p {
    text-align: center;
  }

  > dl {
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    > dt {
      width: 33%;
      padding-top: 1rem;
      text-align: right;
      font-weight: normal;
    }

    > dd {
      width: calc(67% - 2rem);
      margin: 0;
      padding-top: 1rem;
      padding-left: 2rem;
    }
  }
}



.mdl-layout__header {
  background-color: #ffffff;
  color: #222222;

  .mdl-layout__header-row {
    height: 80px;

    > .mdl-layout-title > a {
      color: rgba(0, 0, 0, 0.87);
      text-decoration: none;

      &:hover {
        color: $primary-color;
      }
    }

    .mdl-navigation__link {
      color: #424242;

      &:hover {
        color: $primary-color; // 500
      }

      & + button {
        margin-left: 1rem;
      }
    }
  }
}

/**
  * vendor overwrite
  */
pre.pretty-json {
  outline: 0;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.0;
}

.md-button {
  padding: 0 1rem;
}

md-dialog {
  width: 50vw;
  min-width: 200px;
  max-width: 650px;
}

.publish-form {
  .publish-form__is-sending {
    padding-top: 1rem;

    > * + p {
      margin-top: 1rem;
      text-align: center;
    }
  }
}

/* API key field */
.api-key-field {
  font-size: 20px;
  text-align: center;
}
